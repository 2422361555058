.sidee .nvs{
    display: flex;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    justify-content: space-between;}
.sidee .nvs button{
    padding: 14px;
    font-size: 20px;
    border: none;
    background-color: transparent;
    margin-left: 43px;
}
.sidee .nvs div{
    margin-top: 15px;
    font-size: 18px;
}
.sidee .nvs span{
    color: #b59e6c;
}
.sidee .active {
    color:#fff;
    background-color: #14205c;
  }
  .lli .links{
    display: flex !important;
    flex-direction: column !important;
  }
  @media (max-width: 767px) {
    .sidee .nvs div{
        margin-top: 10px;
        font-size: 14px;
    }
    .sidee .nvs button{
        padding: 5px;
        font-size: 15px;
        border: none;
        background-color: transparent;
        margin-left: 0px;
    }
  }
  .lli .links a{
    background-color: #14205c ;
    text-decoration: none;
    font-size: 21px; 
    padding: 10px;
    color: #fff;
    margin-bottom: 5px; 
    transition: 0.3s;
  }
  .lli .links a:hover{
    background-color: #082ff1 !important;
  }