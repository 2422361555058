#contsctus{
  background-color: #eee;
  padding-top: 10px;

}
#contsctus textarea{
  height: 120px !important;
}
#contsctus form{
  height: 337px;

}
#contact {
    background-color: #eee;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  #contact .contact-box {
    width: clamp(100px, 90%, 1000px);
    margin: 80px 50px;
    display: flex;
    flex-wrap: wrap;
  }
  
  #contact .contact-links, .contact-form-wrapper {
    width: 50%;
    padding: 8% 5% 10% 5%;
  }
  
  
  #contact .contact-links {
    background-color: #14205c;
    background:
      radial-gradient(
        circle at 55% 92%, #14205c 0 12%, transparent 12.2%
      ), 
      radial-gradient(
        circle at 94% 72%, #14205c 0 10%, transparent 10.2%
      ), 
      radial-gradient(
        circle at 20% max(78%, 350px), #b59e6c 0 7%, transparent 7.2%
      ), 
      radial-gradient(
        circle at 0% 0%, #b59e6c 0 40%, transparent 40.2%
      ), 
      #14205c;
    border-radius: 10px 0 0 10px;
  }
  
  #contact  .contact-form-wrapper {
    background-color: #ffffff8f;
    border-radius: 0 10px 10px 0;
  }
  
  @media only screen and (max-width: 800px) {
    #contact .contact-links, .contact-form-wrapper {
      width: 100%;
    }
    
    #contact   .contact-links {
      border-radius: 10px 10px 0 0;
    }
    
    #contact .contact-form-wrapper {
      border-radius: 0 0 10px 10px;
    }
  }
  
  @media only screen and (max-width: 400px) {
    #contact .contact-box {
      width: 95%;
      margin: 8% 5%;
    }
  }
  
  #contact h2 {
    /* font-family: 'Arimo', sans-serif; */
    color: #fff;
    font-size: 36px;
    letter-spacing: 2px;
    text-align: center;
    /* transform: scale(.95, 1); */
  }
  
  #contact .links {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 50px;
  }
  
  #contact .link {
    margin: 10px;
    cursor: pointer;
  }
  
  #contact i {
    width: 45px;
    height: 45px;
    filter: 
      hue-rotate(220deg)
      drop-shadow(2px 4px 4px #0006);
    transition: 0.2s;
    user-select: none;
    color: #fff;
    font-size: 30px;
  }
  
  #contact i:hover {
    transform: scale(1.1, 1.1);
  }
  
  #contact i:active {
    transform: scale(1.1, 1.1);
    filter: 
      hue-rotate(220deg)
      drop-shadow(2px 4px 4px #222)
      sepia(0.3);
      
  }
  
  #contact .form-item {
    position: relative;
  }
  #contact .form-item div {
    width: 100% !important;
    margin-bottom: 11px !important;
  }

  @media only screen and (max-width: 800px) {
    #contact h2 {
      font-size: clamp(40px, 10vw, 60px);
    }
  }
  
  @media only screen and (max-width: 400px) {
    #contact h2 {
      font-size: clamp(30px, 12vw, 60px);
    }
    
    #contact .links {
      padding-top: 30px;
    }
    
    #contact i {
      width: 38px;
      color: #fff;
      height: 38px;
    }
  }
  