.features {
    /* padding-top: 80px; */
    padding: 20px 20px !important;
    /* padding-bottom: 80px; */
    width: 80%;   
    margin: 0 auto;
  }
  .features h2 {
    font-weight: bold;
    font-size: 30px;
    color: #b59e6c;
    margin: 0 0 60px;
    text-align: center;
  } 
  .features .fetcont{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
   .feat-box {
    width: calc(100% / 3);
    text-align: center;
    margin-bottom: 40px;
  }
  @media (max-width: 991px) {
    .features .feat-box {
      width: calc(100% / 2);
    }
  }
  @media (max-width: 575px) {
    .features .feat-box {
      width: 100%;
    }
  }
  .features .feat-box img {
    width: 96px;
    height: 73px;
  }
  .features .feat-box h4 {
    font-size: 22px;
    margin: 15px 0 40px;
    position: relative;
  }
  .features .feat-box h4:before {
    content: '';
    width: 40px;
    height: 4px;
    background-color: #b59e6c;
    position: absolute;
    left: 50%;
    margin-left: -20px;
    bottom: -22px;
  }
  .features .feat-box p {
    width: 80%;
    margin: 0 auto;
    line-height: 1.7;
    color: #706f6f;
  }