.workus{
    margin-top: 150px;
}
.workus 
.workus .proimg input[type="file"]::after{
    width: 33px;
    height: 33px;
    position: absolute;
    background-color: chocolate;
    color: brown;
    padding: 0px;
}
.workus .proimg input[type="file"]::before{
    width: 33px;
    height: 33px;
    position: absolute;
    background-color: chocolate;
    color: brown;
    padding: 0px;
}
.workus  .proimg {
    position: relative;
    display: block;
    width: -moz-fit-content;
    width: 139px;
    margin: 0 auto;
}
.workus  .proimg form{
    padding: 0px !important;
    height: fit-content !important;
    text-align: center;
    background-color: transparent !important;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.workus  img.logoutimg{
    object-fit: cover;
    width: 50px;
    cursor: pointer;
}
.workus  .proimg i{
    color: white;
    padding: 10px;
    background-color: #7174f1;
    border-radius: 50%;
    width: 35px !important;
    height: 35px !important;
}
.workus  .proimg img{
    border-radius: 50%;
    width: 120px;
    height: 120px;
    display: block;
    margin: 0px auto;
    object-fit: cover;
}

.workus .image-editor{
    position: absolute;
    top: 86px;
    right: 46px;
}
.workus .image-editor input{
    position: absolute;
    z-index: 10;
    top: 3px;
    border-radius: 50%;
    width: 35px !important;
    height: 35px !important;
    opacity: 0;
}
.workus .image-editor i {
    position: absolute;
    
}