body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

.engfont *:not(i){
  font-family: 'Playfair Display', serif;

}
.arabfont *:not(i){
  font-family: 'Almarai', sans-serif;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-thumb {
  background:#14205c;
}
::-webkit-scrollbar-thumb:hover {
  background:#fff;
}
.inp-container>div:not(.dateee),.de>div:not(.dateee){
  min-width: auto !important;
  width: 100% !important;     
}  
.inputs-container{
  display: flex;
  flex-wrap: wrap;
  /* margin: 50px auto; */
  justify-content: space-around;
  width: 100%;

}
.inp-container{
  width: 320px !important;
  margin: 10px;
  margin:10px auto;
  font-family: 'Almarai', sans-serif !important;
  position: relative;
} 
.inp-container legend span, .inp-container label{
  font-family: 'Almarai', sans-serif !important;

}

.buttonadd{
  background-color: #b59e6c;
  color: #fff;
  padding: 7px 22px;
  border: none;
  margin: 10px auto;
  font-size: 18px;
  display: block;
  transition: 0.3s;
}
.buttonadd:hover{
  background-color: #d8b260;

}
.dateee{
  position: absolute;
  top: 49%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #14205c;
}
input[type="date"i] {
  margin-left: 38px !important;
  color: #14205c !important;
  opacity: 0;
  text-align: center;
}
.ty{
  color: #14205c !important;
  top: 17px;
  cursor: pointer;
  position: absolute;
  right: 14px;
  font-size: 20px;
}
.cch{
  display: flex;
  justify-content: center;
  font-size: 20px;
  flex-wrap: wrap;
}
.cch .cchs{
  margin: 0px 10px;
}
.cch .cchs input{
  height: 23px;
  width: 21px;
}
.not-allow{
  cursor: not-allowed;
}