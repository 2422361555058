
.six { width: 100% }


/* COLUMNS */

.col {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
}

.col:first-of-type {
  margin-left: 0;
}

.load{
    width: 100%;
    height: 100%;
    /* margin: 0 auto; */
    background-color: rgba(0,0,0,0.5);
    position: relative;
    text-align: center;
}
#loader-6 span{
    display: inline-block;
    width: 5px;
    margin: 2px;
    height: 20px;
    background-color: #14205c;
}

#loader-6 span:nth-child(1){
  animation: grow 1s ease-in-out infinite;
}

#loader-6 span:nth-child(2){
  animation: grow 1s ease-in-out 0.15s infinite;
}

#loader-6 span:nth-child(3){
  animation: grow 1s ease-in-out 0.30s infinite;
}

#loader-6 span:nth-child(4){
  animation: grow 1s ease-in-out 0.45s infinite;
}

@keyframes grow{
  0%, 100%{
    -webkit-transform: scaleY(1);
    -ms-transform: scaleY(1);
    -o-transform: scaleY(1);
    transform: scaleY(1);
  }

  50%{
    -webkit-transform: scaleY(1.8);
    -ms-transform: scaleY(1.8);
    -o-transform: scaleY(1.8);
    transform: scaleY(1.8);
  }
}