.navbar-expand-lg {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    z-index: 10;
  }
  .navbar{
    z-index: 10 !important;
    position: absolute;
    width: 100%;
    background: rgba(0,0,0,0.4);
  }
  .navbar-collapse.show{
    background-color: rgba(0,0,0,0.4) !important;
  }
  .navbar .on{
    width: 79px;
  }
  .navbar .sn{
    width: 80px;
  }
  .navbar .tn{
    width: 75px;
  }
  .active {
    color:#fff !important;
    border-bottom: 2px solid #fff;
    z-index: 10;
  }
  .nav-link{
    font-size: 18px;
    font-weight: 300;
    color: #fff;
    margin: 6px;
    z-index: 10;
  }
  .nav-link:hover{
    color: #ddd;
  }
  .nav-link:active{
    color: #ddd;
  }
  @media (max-width: 768px) {
    .nav-link{
      font-size: 17px;
      margin: 2px;
    }
    .navbar .on{
      width: 50px;
    }
    .navbar .sn{
      width: 50px;
    }
    .navbar .tn{
      width: 46px;
    }
    .navbar h6{
      font-size: 15px;
    }
  }

  .navbar-nav{
  margin-left: auto !important;
  z-index: 10;
  color: #fff;
  /* flex-direction:row ; */
  }
  /* .navbar img{
    width: 70px !important;
  } */
  .dropdown-menu.show{
    background-color: rgba(0,0,0,0.4) !important;
    top: 68px !important;
    border-radius: 0px !important;
  }
  .dropdown-menu.show a{   
    color: #fff !important;
  }
  .dropdown-item:focus , .dropdown-item:hover {
    background-color: rgba(0, 0, 0, 0.2) !important;
    color: #14205c !important; 
  } 
  .navbar-nav .nav-link.active, .navbar-nav .nav-link.show{
    color: #ddd;
  }
  .navbar-brand{
    display: flex !important;
  }
   .log {
    margin-left: 10px;
    color: #fff;
  }
 .log h6{
    text-transform: uppercase;
    font-weight: 300;
    text-align: center;
  }
  .log:last-child h6{
    position: relative;
    bottom: 3px;
  }
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "ع";
    height: 26px;
    text-align: center;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #14205c;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #14205c;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }