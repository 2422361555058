.facil
    {
        padding-top:120px;
        padding-bottom: 80px;
        background-color: #fff; 
        overflow: hidden;
    }
.facil h2
    {
        padding-bottom: 20px;
        color: #14205c;
    }
.facil .btn
    {
        border-radius: 0px;
        font-weight: bold;
        margin-top: 20px;
        background-color:#b59e6c ;
        padding: 10px 40px 10px 40px;
    }
    .facil img{
        width: 50%;
        margin: 0 auto;
        display: block;
    }
.facil iframe{
    width: 100%;
    height: 353px;
    border-radius: 43px;
    margin-bottom: 20px;
}
.facil hr{
    width: 40%;
    margin: 0 auto;
}
.uss p{
    border: 3px dotted #b59e6c ;
    padding: 25px;
    width: 70%;
    text-align:end;
    display: block;
    margin: 0 auto;
}