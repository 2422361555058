
.Popup{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 50px auto;
}
.Popupflag .gala{
  width: 289px;
  height: 327px;
  position: relative;
  border-radius: 20px;
  transition: .4s;
  overflow: hidden;
  margin: 10px;
}
.Popupflag .gala img{
  object-fit: cover;
  transition: .4s;
  width: 295px;
  height: 330px;
}
.Popupflag .gala .overlayegala{
  position: absolute;
  min-height: 100%;
  width: 0%;
  left:0;
  top:0;
  z-index:2;
  background-color: rgb(0,0,0,0.4);
  transition: .4s;
  
}
.gala:hover img {
  transform: scale(1.2); 
}
.gala:hover .overlayegala{
  position: absolute;
  min-height: 100%;
  width: 100%;
  left:0;
  top:0;
  z-index:2;
  background-color: rgb(0,0,0,0.4);

  
}
.gala:hover .cont{
  opacity: 1 !important;
}
.Popupflag .gala .cont{
  z-index: 99;
  position: absolute;
  top: 25px;
  left: 9px;
  transition: .4s;
  font-size: 17px;
  color: #fff;
  opacity: 0;
}