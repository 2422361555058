.timeline {
    padding-top: 20px;
    padding-bottom: 50px;
    background-color: #EEE; 
    
  }
  
  .timeline .timeline-content {
    position: relative;
    overflow: hidden;
  }
  .timeline .timeline-content:before {
    content: '';
    width: 2px;
    height: 100%;
    background-color:#b59e6c;
    position: absolute;
    left: 50%;
    margin-left: -1px;
    top: 0;
  }
  .timeline .timeline-content .year {
    margin: 20px auto;
    width: 50px;
    background-color:#b59e6c;
    position: relative;
    z-index: 2;
    border-radius: 4px;
    text-align: center;
    color: #FFF;
    padding: 2px 5px;
    font-weight: bold;
  }
  .timeline .timeline-content .left,
  .timeline .timeline-content .right {
    width: calc(50% - 25px);
    margin-bottom: 40px;
    position: relative;
  }

  @media (max-width: 767px) {
    .timeline .timeline-content .left, 
    .timeline .timeline-content .right {
      width: 100%;
      margin-bottom: 20px;
    }
    .timeline .timeline-content .left:before, 
    .timeline .timeline-content .right:before {
      display: none;
    }
    .timeline .timeline-content .left .content:before,
    .timeline .timeline-content .right .content:before {
      display: none;
    }
  }
  .timeline .timeline-content .left {
    float: left;
  }
  .timeline .timeline-content .left:before {
    right: -32px;
  }
  .timeline .timeline-content .right {
    float: right;
  }
  .timeline .timeline-content .right:before {
    left: -32px;
  }
  .timeline .timeline-content .left:before,
  .timeline .timeline-content .right:before {
    content: '';
    width: 14px;
    height: 14px;
    background-color: #FFF;
    border: 3px solid #14205c;
    position: absolute;
    border-radius: 50%;
    top: 20px;
  }
  .timeline .timeline-content .content {
    padding: 20px;
    background-color: #FFF;
    overflow: hidden;
  }
  .timeline .timeline-content .content h3 {
    font-weight: bold;
    color:#b59e6c;
    margin: 0 0 10px;
  }
  .timeline .timeline-content .content p {
    color: #b59e6c;
    margin: 0;
    line-height: 1.6;
  }
  .clearfix {
    clear: both;
  }
  .timeline .timeline-content .left .content:before {
    content: '';
    border-style: solid;
    border-width: 10px;
    border-color: transparent transparent transparent #FFF;
    height: 0;
    width: 0;
    position: absolute;
    right: -20px;
    top: 20px;
  }
  .timeline .timeline-content .right .content:before {
    content: '';
    border-style: solid;
    border-width: 10px;
    border-color: transparent #FFF transparent transparent ;
    height: 0;
    width: 0;
    position: absolute;
    left: -20px;
    top: 20px;
  }