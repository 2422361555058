
/* start intro */       
/* #intro
    {
    background: url('../images/home-bg.jpg') no-repeat;
    background-size: cover;
    color: #fff;
    text-align: center;
    width: 100%;
    }
#intro p
    {
        font-weight: 400;
        line-height: 2em;
        font-size: 18px;
        background-color: rgba(0,0,0,0.4);
        padding: 5px;
    }
#intro img
    {
        display: inline-block;
        margin-top: 30px;
    } */
    .car{
        position: relative;
    }
    
    .carousel-inner{
        height: 100vh;
        position: relative;
    }
    .car .overlay{
        position: absolute;
        min-height: 100%;
        width: 100%;
        left:0;
        top:0;
        z-index:2;
        background-color: rgb(0,0,0,0.4);
    }
    .carousel-caption{
        top: 50% !important;
        left: 50% !important;
        position: absolute !important;
        right:0 !important;
        transform: translate(-50%, -50%) !important;
        z-index: 13 !important;
        display: block !important;
        color: #fff !important;
        padding: 0px !important;
        bottom: 0 !important;
    }
    .carousel-control-next, .carousel-control-prev{
        z-index: 3 !important;
        display: none !important;
    }
    /* .carousel-item{
        position: initial !important;
    } */
    .car p{
        font-size: 22px;
        font-weight: 300;
        z-index: 1000;    
    }
    .car h1{ 
        display: inline-block;
        text-transform: uppercase;
        
        margin-right: -0.3em;
        position: relative;
        font-weight: 300;
        font-size:30px !important;
        color: #fff;
      }
    
    .car video{
        height: 100vh;
        object-fit: cover;
    }
@media (max-width: 768px){
    .car p{
        font-size: 15px;
        font-weight: 300;
        z-index: 1000;
    }
    .car h1{ 
        display: block;
        text-transform: uppercase;
        position: relative;
        font-weight: 300;
        text-align: center;
        font-size:20px !important;
        color: #fff;
      }
      .carousel-inner {
        height: 100%;
        position: relative;
    }
    .car video{
        height: 100%;
        object-fit: contain;
    }
    .navbar{
        z-index: 10 !important;
        position: absolute;
        height: 100px;
        width: 100%;
        background: rgba(0,0,0,0.4);
    }
 
}
.slider-caption{
    z-index: 88 !important;
}
 .carousel-caption.active{ 
    z-index: 8 !important;
}
.carousel-fade .carousel-item-next.carousel-item-start, .carousel-fade .carousel-item-prev.carousel-item-end, .carousel-fade .carousel-item.active{
    z-index: auto !important;
}

  .car h1 span{
    color:#b59e6c ;
  }
  .tit{ 
    text-transform: uppercase;
    text-align: center;
    position: relative;
    font-weight: 300;
    margin: 20px 0px;
    color: #14205c;
    
  }
  .navbar-toggler{
    background-color: #b59e6c;

  }
/* end intro */    