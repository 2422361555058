.about
    {
        padding-top: 20px;
        padding-bottom: 80px;
        background-color: #fff; 
        overflow: hidden;

    }
.about h2
    {
        padding-bottom: 20px;
        color: #14205c;
    }
.about .btn
    {
        border-radius: 0px;
        font-weight: bold;
        margin-top: 20px;
        background-color:#b59e6c ;
        padding: 10px 40px 10px 40px;
    }
    .about img{
        width: 50%;
        margin: 0 auto;
        display: block;
    }