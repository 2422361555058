.mainadminpage{
    margin-top: 170px;
    display: flex;
    justify-content: space-around;
    text-align: center;
    flex-wrap: wrap;
}
.infoo{
    width: 100%;
    background: #14205c;
    color: #fff;
}
.infoo h2{
    box-shadow: 0 16px 25px rgba(0,0,0,0.25), 0 11px 11px rgba(0,0,0,0.25);
    width: -moz-fit-content;
    width: fit-content;
    padding: 15px;
    display: block;
    margin: 20px  auto; 
}
.imges{
    width: 450px;
    flex-wrap: wrap;
}
.imges img{
    width: 100%;
    /* animation: uptodown 5s linear infinite ;
    position: relative; */
}
/* @keyframes uptodown{
    0%,100%{
        top:0;
    }
    50%{
        top:-50px;
    }
  }  */