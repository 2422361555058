.up{
    /* text-align: center; */
    color: white;
    z-index: 999999;
    width: 40px;
    position: fixed;
    bottom: 20px;
    background: #b59e6c;
    right: 20px;
    border: none;
    display: none;
    padding: 6px;
}