.activites{
    margin-top: 120px;
}
.activites
    {
        padding-top: 20px;
        padding-bottom: 80px;
        background-color: #fff; 
        overflow: hidden;
    }
.activites h2
    {
        padding-bottom: 20px;
        color: #14205c;
    }
.activites .btn
    {
        border-radius: 0px;
        font-weight: bold;
        margin-top: 20px;
        background-color:#b59e6c ;
        padding: 10px 40px 10px 40px;
    }
    .activites img{
        width: 50%;
        margin: 0 auto;
        display: block;
    }
.activites iframe{
    width: 100%;
    height: 353px;
    border-radius: 43px;
    margin-bottom: 20px;
}
.activites hr{
    width: 40%;
    margin: 0 auto;
}