.login-container{
    /* position: absolute; */
    /* left: 50%; */
    padding: 22px;
    /* top: 50%; */
    height: fit-content;
    margin: 100px auto;
    /* transform: translate(-50%, -50%); */
    box-shadow: 0 16px 25px rgba(0,0,0,0.25), 0 11px 11px rgba(0,0,0,0.25);
    width: 450px;
    /* margin-bottom: 100px; */
}
.login-container .login button svg{
    position: relative;
    top: -2px;
    left: 1px;
}
@media  (max-width: 600px){
    .login-container{
        width: 90%;
    }
}
.login-container h2{
    color: #14205c;
    font-weight: bold;
}
.login-container .login{
    display: flex;
    flex-direction: column;
    margin-top: 33px;
    position: relative;
}
.login-container .login label{
    color: #333;
    font-size: 17px;
    margin-bottom: 5px;
}
.login-container .login input{
    margin-bottom: 10px;
    padding: 8px;
    font-size: 17px;
    width: 98%;
}
.login-container .login input:focus-visible{
    outline: none !important;
    border-color:#ddd !important ;
}
.login-container .login button{
    border: none;
    padding: 11px;
    background-color: #14205c;
    color: #fff;
    font-size: 17px;
}
.login-container a{
    text-decoration: none;
    margin: 5px 0px;
}
.login-container p{
    color: red;
    font-size: 15px;
    font-weight: bold;
}
.login .e{
    position: absolute;
    /* left: 6px; */
    top:61px;
    /* height: 46px; */
    padding: 3px 0px !important;
    border-radius: 50%;
    height: 29px;
    width: 29px;
    right: 14px;
}
.login .c{
    position: absolute;
    right: 14px;
    top: 113px;
    /* height: 46px; */
    padding: 3px 0px !important;
    border-radius: 50%;
    height: 29px;
    width: 29px ;
}

.login .social{
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 5px 0px;
    color: #fff;
}
.login .social i:first-child{
    background-color: #4867AA;
    /* width: 43px; */
    padding: 8px;
    font-size: 23px;
    border-radius: 50%;
    margin-left: 5px;
    cursor: pointer;
}
.login .social i:last-child{
    background-color: #E94235;
    /* width: 43px; */
    padding: 8px;
    cursor: pointer;
    font-size: 23px;
    border-radius: 50%;
    margin-right: 5px;
}
.main-log{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 120px;
}
.main-log .content-main{
    padding: 12px;
    width: 50%;
}
@media (max-width: 1000px){
    .main-log{
        display: flex;
        justify-content: space-between;
        width: 100%;
        flex-direction: column-reverse; 
        flex-wrap: wrap;
        margin-top: 120px;
    }
    .main-log .content-main {
        padding: 12px;
        margin-top: 20px;
        width: 100%;
    }
}
.main-log .content-main h2,.main-log .content-main h3{
    color: #14205c;
}
.main-log .content-main p{
    color: rgb(85,85,85);
    font-size: 19px;
    font-weight: 500;
}
.main-log .content-main ul{
    list-style: none;
}
.main-log .content-main ul li{
    color: rgb(85,85,85);
    margin: 10px 0px;
    font-size: 18px;
}
.main-log .content-main ul li i{
    color: #14205c;
    margin-left: 5px;
}

.login #bar-progress {
    width: 100%;
    display: inline-flex;
    justify-content: center;
    margin-top: 30px !important;
}
.login #bar-progress .step h5{ 
    font-size: 25px !important;
    color: #14205c;
    margin: 0px !important;
    font-weight:400 !important;
}
@media (max-width: 768px) {
    .login #bar-progress .step h5{
        font-size: 17px !important;
    }
    #bar-progress .step{
        width: fit-content !important;
    }
    #bar-progress .seperator {
        margin: auto 2px !important;
    }


}