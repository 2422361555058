.events
    {
        padding-top: 20px;
        padding-bottom: 80px;
        background-color: #eee; 
        overflow: hidden;
    }
.events h2
    {
        padding-bottom: 20px;
        color: #14205c;
    }
.events .btn
    {
        border-radius: 0px;
        font-weight: bold;
        margin-top: 20px;
        background-color:#b59e6c ;
        padding: 10px 40px 10px 40px;
    }
    .events img{
        width: 50%;
        margin: 0 auto;
        display: block;
    }
.events iframe{
    width: 100%;
    height: 353px;
    border-radius: 43px;
    margin-bottom: 20px;
}
.events hr{
    width: 40%;
    margin: 0 auto;
}