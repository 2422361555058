.table{
    width: 95%;
    box-shadow: 0 16px 25px rgba(0,0,0,0.25), 0 11px 11px rgba(0,0,0,0.25);
    margin: 70px auto !important;
}
.table>thead ,.table>thead tr th{
    color: #fff !important;
    background-color: #b59e6c !important;
    box-shadow: 0 8px 4px rgba(0, 0, 0, 0.121), 0 1px 7px #b59e6c;
    }
    .table>thead tr th{
        transition: 0.3s !important;
       
    }
.table>thead tr th:hover{
    color: #fff !important;
    background-color:#b59e6c !important;
    cursor:default ;   
    }
.table i{
        cursor: pointer;
        transition: 0.3s;
        margin-right: 4px;
    }
/* .table i:hover{
    color: #fff !important;
    background-color:#b59e6c  !important;;
} */
.table .ex{
    transform: rotate(181deg);
}
.icon-w{
    width: 44px !important;
    max-width: 44px !important;
}
.icon-ww{
    max-width: 85px !important;
    width: 85px !important;     
}
.text-left{
    text-align: end !important;
}
.text-left i{
    color: #b59e6c !important;
}